<template>
  <div class="top">
      <div class="display-flex jc-space-between">
        <div class="display-flex  fd-column" style="color: #FFFFFF;padding: 20px 16px;margin-top: 10px;">
          <img :src="wordImg"  class="mt-10 mb-16">
          <span class="fs-16 ">随时随地可查</span>
        </div>
        <img :src="searchImg"  class="mt-22" style="position: absolute;z-index: 0;right: 36px" >
      </div>
      <div style="background: #FFFFFF;padding: 20px;z-index: 55;position: relative;border-radius: 12px;height: 0;flex: 1;">
        <b >请输入客户名称查询</b>
<!--        <Input v-model="value" placeholder="请输入..." style="position: relative;margin-top: 16px;" size="large" ></Input>-->
        <Select
          v-model="value"
          filterable
          size="large"
          class="mt-20"
          :label-in-value="true"
          @on-change="changeSel"
          placeholder="请输入"
          :remote-method="remoteMethod2"
          :loading="remoteLoading">
          <Option v-for="option in vagueList" :value="option.value" :key="option.value">
            {{option.label}}
          </Option>
        </Select>
        <div class="word" @click="search">搜索</div>
        <template  v-if="list.length">
          <div class="mt-20">
            <b>校验信息</b>
            <p style="color: #999999;font-size: 12px;margin-top: 12px;margin-bottom: 16px">请补全一个或多个联系人姓名和号码，以用来查询项目</p>
          </div>
          <div class="list" v-for="(item,index) in list" :key="item.id">
            <span style="color: #3377FF;font-size: 12px">联系人{{index+1}}</span>
            <div class="display-flex" style="margin-top: 16px">
              <div class="left display-flex fd-column">
                <b  class="mb-10">{{item.name}}</b>
                <Input :ref="`input${index}0`"  class="write" type="text" maxlength="1" v-model="item.input0" @keyup.native="inputKey(index,0,item.input0)" />
              </div>
              <div class="right display-flex fd-column">
                <b class="mb-10">{{item.phone}}</b>
                <div class="phone">
                <span style="display: flex">
                  <Input :ref="`input${index}1`" class="write" type="number" maxlength="1" v-model="item.input1" @keyup.native="inputKey(index, 1,item.input1)" />
                  <Input :ref="`input${index}2`" class="write" type="number" maxlength="1" v-model="item.input2" @keyup.native="inputKey(index, 2,item.input2)" />
                  <Input :ref="`input${index}3`" class="write" type="number" maxlength="1" v-model="item.input3" @keyup.native="inputKey(index, 3,item.input3)" />
                  <Input :ref="`input${index}4`" class="write" type="number" maxlength="1" v-model="item.input4" @keyup.native="inputKey(index, 4,item.input4)" />
                </span>
                </div>
              </div>
            </div>
          </div>
          <Button type="primary" long  @click="submit" :loading="loading">确认查询</Button>
        </template>

      </div>
    </div>
</template>

<script>
import searchImg from '@/views/h5/img/search.png'
import wordImg from '@/views/h5/img/wordImg.png'
export default {
  name: 'Search',
  components: {},
  props: {},
  data () {
    return {
      loading: false,
      searchImg,
      wordImg,
      selId: '',
      remoteLoading: false,
      value: '',
      list: [],
      vagueList: [],
      customerId: ''
    }
  },
  filters: {},
  computed: {},
  watch: {

  },
  methods: {
    search () {
      if (this.value.trim()) {
        this.searchPeopleList()
      } else {
        this.$Message.warning('不能为空')
      }
    },
    searchPeopleList () {
      this.$api.follow.searchUserList({ customerId: this.selId }).then(res => {
        this.$Message.success('查询成功')
        this.list = res.list.map(item => {
          return {
            ...item,
            input1: null,
            input2: null,
            input3: null,
            input4: null,
            input0: null
          }
        })
      })
    },
    changeSel (data) {
      this.selId = data.value
      this.searchPeopleList(data.value)
    },
    inputKey (index, _index, value) {
      if (value) {
        this.$refs[`input${index}${_index + 1}`][0].focus()
      }
    },
    remoteMethod2 (query) {
      if (query !== '') {
        this.remoteLoading = true
        setTimeout(() => {
          this.remoteLoading = false
          this.$api.follow.search({ customerName: query }).then(res => {
            this.vagueList = res.map(item => {
              return {
                ...item,
                value: item.id,
                label: item.name
              }
            })
          })
        }, 200)
      } else {
        this.vagueList = []
      }
    },
    submit () {
      this.loading = true
      const list = this.list.map(item => {
        return {
          id: item.id,
          nameEnd: `${item.input0}`,
          phoneEnd: `${item.input1}${item.input2}${item.input3}${item.input4}`
        }
      })
      const data = {
        customerId: this.selId,
        contract: list
      }
      this.$api.follow.submit(data).then(res => {
        this.loading = false
        this.$localStorage.set('list', res)
        this.$Message.success('查询成功')
        this.$router.push({
          path: '/h5-search/result'
        })
      }).catch((err) => {
        this.$Message.warning(err.message)
        this.loading = false
      })
    }

  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>

.top{
  min-height: 100vh;
  background: linear-gradient(180deg, #3377FF 0%, #F7F7F7 30%);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
/deep/
.ivu-card-bordered {
  border: none!important;
  border-radius: 12px;
}
/deep/
.ivu-card-head {
   border: none;
   padding: 14px  16px 0 16px!important;
}
/deep/
.ivu-input{
  border: 1px solid #73A1FC;
}
.word{
  border-left: 1px solid #3377FF;
  width:45px;
  position: absolute;
  right: 32px;
  top: 74px;
  color: #3377FF;
  font-size: 14px;
  padding-left: 10px;
}
.list{
  border: 1px solid #E6E6E6;
  padding:16px ;
  margin-bottom: 20px;
  border-radius: 4px;
  .left{
    /deep/
    .ivu-input {
      display: inline-block;
      width: 36px;
      height: 36px;
    }
    margin-right: 46px;
  }
  .right{
    .phone {
      text-align: center;
      display: flex;
      flex-direction: column;
      /deep/
      .ivu-input {
        display: inline-block;
        width: 36px;
        height: 36px;
      }
      .write{
        margin-right: 10px;
      }

    }
  }
}
/deep/
.ivu-btn {
  border-radius: 20px;
}
/deep/
.ivu-select-input{
  padding: 0 56px 0 8px;
}

/deep/ .ivu-select{
  position:relative;
}
/deep/ .ivu-select .ivu-select-dropdown{
  width: 100% !important;
  min-width:auto !important;
}
/deep/.ivu-select-large .ivu-select-input{
  font-size: 14px;
}
</style>
